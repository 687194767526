import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';

// Sentry
import { init as SentryInit } from '@sentry/capacitor';
import { init as sentryAngularInit, createErrorHandler } from '@sentry/angular';

// Capacitor
import { Capacitor } from '@capacitor/core';

try {
  SentryInit(
    {
      enabled: Capacitor.isNativePlatform() && environment.production,
      dsn: 'https://47082555300649dfbfd3c4ad38781e40@o453168.ingest.sentry.io/4504564616724480',
      // Optional: Use ignoreErrors and ignoreUrls for additional filtering
      ignoreErrors: [
        /Indexed Database/i, // The 'i' flag makes the regex case-insensitive
        /Attempt to iterate a cursor that doesn't exist/i,
      ],
      ignoreUrls: [
        /https:\/\/www\.btc-echo\.de\/reports\/loadframe\/.*/
      ],
    },
    // Forward the init method from @sentry/angular
    sentryAngularInit
  );
} catch (error) {
  console.error('Sentry init error:', error);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: ErrorHandler, useValue: createErrorHandler({ logErrors: environment.production }) }
  ]
})
export class SentryModule { }
