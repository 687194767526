import { Injectable, ElementRef, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Platform, NavController, ModalController } from '@ionic/angular';
import { Browser, OpenOptions } from '@capacitor/browser';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LoggerService } from './logger.service';
import { ZoomableImageComponent } from '../shared/zoomable-image/zoomable-image.component';
import { SettingsService } from './settings.service';
import { slideVerticalTransitionSimple } from '../animations/animations';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private supportedPaths = ['news', 'kurs', 'schlagzeilen', 'videos', 'podcast', 'newsticker', 'shop', 'event', 'reports'];
  private router = inject(Router);

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private loggerService: LoggerService,
    private modalController: ModalController,
    private settingsService: SettingsService) { }

  getSupportedBasePaths() {
    return this.supportedPaths;
  }

  parseContent(content: ElementRef, meta?: LinkMeta) {
    this.parseLinks(content?.nativeElement?.getElementsByTagName('a') || [], meta);
    this.parseImages(content?.nativeElement?.getElementsByTagName('img') || []);
    this.resizeIframes(content?.nativeElement.getElementsByTagName('iframe') || []);
    this.parseTweets(content?.nativeElement?.getElementsByClassName('is-provider-twitter') || []);
  }

  /**
   * This function turns the post content in an array with helpful metadata
   *
   * @param postContent html as string
   * @returns array of post sections
   */
  analyzePostSections(postContent: string) {

    try {

      const getChildrenAsArray = () => {
        const container = document.createElement('div');
        container.innerHTML = postContent;
        return Array.from(container.children);
      };

      const sections: PostSection[] = [];

      for (const child of getChildrenAsArray()) {
        if (child.classList.contains('btce-podcast')) {

          const scriptEl = child.getElementsByClassName('podigee-podcast-player').item(0) as HTMLScriptElement;
          const episodeLink = scriptEl.getAttribute('data-configuration');
          const channelId = new URL(episodeLink).host.split('.').shift();
          const episodeId = Number(new URL(episodeLink).pathname.split('/').find(el => el.includes('-')).split('-').shift());

          sections.push({
            type: 'podcast',
            html: this.sanitizer.bypassSecurityTrustHtml(child.outerHTML),
            attributes: { channelId, episodeId }
          });
        } else if (child.classList.contains('is-type-video')) {

          const iframe = child.getElementsByTagName('iframe').item(0) as HTMLIFrameElement;
          const videoLink = iframe.src;
          const videoId = new URL(videoLink).pathname.split('/').pop();

          sections.push({
            type: 'video',
            html: this.sanitizer.bypassSecurityTrustHtml(child.outerHTML),
            attributes: { videoLink, videoId }
          });
          // check if any of the children ( also grandchildren ) include a id for fade out
        } else if (child.classList.contains('cvwp-video-player')) {

          const videoId = child?.getAttribute('data-video_id');
          const videoLink = `https://www.youtube-nocookie.com/embed/${videoId}`;

          sections.push({
            type: 'video',
            html: this.sanitizer.bypassSecurityTrustHtml(child.outerHTML),
            attributes: { videoLink, videoId }
          });
          // check if any of the children ( also grandchildren ) include a id for fade out
          // @ts-ignore 
        } else if (child.outerHTML.includes('btc-teaser-type-magazin' || 'btc-teaser-type-report' || 'btc-teaser-type-misc')) {
          // create a var with child.outerHTML as object to get access to the children
          const teaser = document.createElement('div');
          teaser.innerHTML = child.outerHTML;
          // get the title of the readmore button
          const fadeOutTitle = teaser.getElementsByClassName('btbl-text-xl')[0].innerHTML;
          // get the description of the readmore button
          const fadeOutDescription = teaser.getElementsByClassName('btbl-relative')[1].children[2].innerHTML;
          // get the link of the readmore button
          const fadeOutButtonLinkHref = teaser.getElementsByTagName('a').item(0).getAttribute('href');
          // get the link of the readmore button
          const fadeOutButtonLinkText = teaser.getElementsByTagName('a').item(0).innerHTML;

          // check if the teaser is a magazine, report or misc
          const type = child.outerHTML.includes('btc-teaser-type-magazin')
            ?
            'fade-out-readmore'
            :
            child.outerHTML.includes('btc-teaser-type-report')
              ?
              'fade-out-free-download'
              :
              'fade-out-misc';

          sections.push({
            type,
            html: this.sanitizer.bypassSecurityTrustHtml(child.outerHTML),
            attributes: {
              fadeOutTitle,
              fadeOutDescription,
              fadeOutButtonLinkHref,
              fadeOutButtonLinkText
            }
          });
        } else if (child.id === 'btc-paywall-app') {
          sections.push({
            type: 'paywall',
            html: this.sanitizer.bypassSecurityTrustHtml(child.outerHTML),
          });
        } else {
          sections.push({
            type: 'text',
            html: this.sanitizer.bypassSecurityTrustHtml(child.outerHTML),
          });
        }

      }

      return sections;

    } catch (error) {
      console.error('Error while parsing post sections', error);
      return [];
    }

  }


  /**
   * Searches for all links in the post and replace them with a
   * custom function, so anchor links won't open in system browser.
   */
  parseLinks(elements: HTMLAnchorElement[], meta?: LinkMeta) {
    for (const a of elements) {
      a.childNodes.forEach(child => {
        if (child instanceof HTMLPictureElement) {
          a.onclick = async (mouseEvent) => {
            mouseEvent.preventDefault(); // disable default behavior of anchor tag
          };
        } else {

          // Handle click event
          a.onclick = async (mouseEvent) => {
            mouseEvent.preventDefault(); // disable default behavior of anchor tag
            await this.handleLink(a.href, {
              source: 'Post',
              title: meta?.title
            }); // custom click event handler
          };

          // Place icon after external links
          let isExternalLink = false;
          if (a.href.startsWith('http') && a.href.includes('btc-echo.de')) {
            const urlObj = new URL(a.href);
            const segments = urlObj.pathname.split('/').filter(seg => seg);
            const basePath = segments[0];
            isExternalLink = !this.router.config?.some(route => route.path.includes(basePath));
          } else if (a.href.startsWith('/')) {
            const segments = a.href.split('/').filter(seg => seg);
            const basePath = segments[0];
            isExternalLink = !this.router.config?.some(route => route.path.includes(basePath));
          } else {
            isExternalLink = true;
          }

          if (isExternalLink) {
            a.insertAdjacentHTML('afterend', '<ion-icon name="open-outline" color="primary" style="margin: 0 0 -2px 2px;"></ion-icon>');
          }
        }
      });

    }
  }

  parseImages(elements: HTMLImageElement[]) {
    for (const img of elements) {
      img.draggable = false; // disable dragging
      img.onclick = async (mouseEvent) => {
        await this.openPhotoViewer(img.src); // custom click event handler
      };
    }
  }

  /**
   * Decide how the different types of links should be handled
   *
   * Supported Links Example:
   * -> btcecho://news/neuer-rekord-droht-eine-blase-im-non-fungible-token-nft-sektor-112057
   * -> https://www.btc-echo.de/news/neuer-rekord-droht-eine-blase-im-non-fungible-token-nft-sektor-112057
   * -> /news/neuer-rekord-droht-eine-blase-im-non-fungible-token-nft-sektor-112057
   *
   * @param href the anchor link
   */
  async handleLink(url: string, meta?: LinkMeta): Promise<void> {

    console.log('handleLink', url);

    if (typeof url !== 'string') {
      return;
    }

    try {

      const isImageLink = ['.jpg', '.jpeg', '.png', '.gif', '.webp'].some(ext => url.includes(ext)); // e.g. https://domain.com/xy/btc.png
      const isVideoLink = ['.mp4', '.webm'].some(ext => url.includes(ext)); // e.g. https://domain.com/xy/btc.mp4
      const isDeepLink = url.startsWith('btcecho:'); // e.g. btcecho://news/this-is-a-post/
      const isRelativeLink = !url.startsWith('http') && !url.startsWith('btcecho'); // e.g. /news/this-is-a-post/
      const isWebsiteLink = url.includes('btc-echo.de'); // e.g. https://www.btc-echo.de/news/this-is-a-post/
      const isLocalLink = url.includes(window.location.host);
      const isExternalLink = !url.includes('btc-echo.de') && !url.includes('btcecho');

      // Open images in photo viewer
      if (isImageLink && !isRelativeLink) {
        console.log('handleLink - Image Link');
        return this.openPhotoViewer(url);
      }

      // Open videos in browser - maybe we integrate a video player later
      if (isVideoLink && !isRelativeLink) {
        console.log('handleLink - Video Link');
        return this.openExternalLink(url, meta);
      }

      if (isRelativeLink) {
        console.log('handleLink - Relative Link');
        const segments = url.split('/').filter(seg => seg);
        const basePath = segments[0];

        if (this.router.config?.some(route => route.path.includes(basePath))) {
          await this.navCtrl.navigateForward(url);
        } else {
          await this.openExternalLink('https://www.btc-echo.de/' + segments.join('/'), meta);
        }
        return;
      }

      if (isExternalLink) {
        console.log('handleLink - External Link');
        // Log external click event in firebase
        return this.openExternalLink(url, meta);
      }

      if ((isDeepLink || isLocalLink || isWebsiteLink)) {

        const urlObj = new URL(url);
        const segments = (isDeepLink ? (urlObj.host + urlObj.pathname) : urlObj.pathname).split('/').filter(seg => seg);
        const basePath = isDeepLink ? urlObj.host : segments[0];

        if (this.router.config?.some(route => route.path.includes(basePath))) {
          console.log('handleLink - Supported Website Link');
          await this.navCtrl.navigateForward('/' + segments.join('/') + urlObj.search, {
            animation: slideVerticalTransitionSimple
          });
        } else {
          console.log('handleLink - Unsupported Website Link (1)');
          return this.openExternalLink(url, meta);
        }

      } else if (url.startsWith('http')) {
        console.log('handleLink - Unsupported Website Link (2)');
        return this.openExternalLink(url, meta);
      }

    } catch (error) {
      // Try to open url anyways
      await this.openExternalLink(url, meta);
      console.log(error);
    }
  }

  /**
   * Opens the link in the in-app-browser and tracks the event in firebase
   *
   * @param url https://...
   */
  async openExternalLink(url: string, meta?: LinkMeta, options?: Partial<OpenOptions>) {

    if (typeof url !== 'string') {
      return;
    }

    if (url.startsWith('http')) {
      // Log external click event in firebase
      await this.loggerService.logExternalLinkClickEvent({
        source: meta?.source,
        title: meta?.title,
        url
      });
      // Open url in in-app-browser
      const theme = await this.settingsService.getTheme();

      const opts: OpenOptions = {
        url,
        toolbarColor: theme === 'dark' ? '#000000' : '#ffffff',
        ...options
      };

      await Browser.open(opts);

    }
  }

  /**
   * Parse embedded tweets
   */
  parseTweets(elements: HTMLElement[]) {
    for (const tweet of elements) {
      const tweetLink = (Array.from(tweet.getElementsByTagName('a')).pop() as HTMLAnchorElement).getAttribute('href');
      const tweetLinkPath = new URL(tweetLink).pathname;
      const tweetId = tweetLinkPath.split('/').pop();

      // remove old tweet element
      tweet.querySelector('.wp-block-embed__wrapper').remove();

      // insert new tweet element
      ((window as any)?.twttr?.widgets.createTweet(
        tweetId,
        tweet,
        {
          dnt: true, // enable privacy options
          lang: 'de',
          theme: 'dark', // TODO: toggle dark mode
          width: window.innerWidth - 40
        }
      ) as Promise<HTMLElement>);
    }
  }

  /**
   * Resizes all iFrames by keeping their original ratio
   */
  resizeIframes(elements: HTMLIFrameElement[]) {
    for (const iframe of elements) {
      const maxWidth = window.innerWidth - 40; // subtract padding
      const maxHeight = window.innerHeight;
      const srcWidth = Number(iframe.width);
      const srcHeight = Number(iframe.height);
      if (srcWidth && srcHeight) {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        iframe.height = String(srcHeight * ratio);
        iframe.width = String(srcWidth * ratio);
      }
    }
    // https://stackoverflow.com/questions/3971841/how-to-resize-images-proportionally-keeping-the-aspect-ratio
  }

  /**
   * This function removes the id at the end of a slug
   * Why is this function helpful?
   * URLs of linked posts contain the id at the end of the slug,
   * but post can only be loaded by the slug without the id.
   *
   * @param slug e.g. test-neue-schlagzeilen-123418
   * @returns e.g. test-neue-schlagzeilen
   */
  removeIdFromSlug(slug: string) {
    const slugSegments = slug.split('-');
    const lastSegment: any = slugSegments[slugSegments.length - 1];
    const lastSegmentIsNumeric = !isNaN(parseFloat(lastSegment)) && !isNaN(lastSegment - 0);
    if (lastSegmentIsNumeric) {
      // remove last segment
      slugSegments.pop();
    }
    return slugSegments.join('-');
  }

  getIdFromSlug(slug: string) {
    const slugSegments = slug.split('-');
    const lastSegment: string = slugSegments[slugSegments.length - 1];
    return Number(lastSegment);
  }

  getSlugFromUrl(url: string) {

    if (!url) {
      return undefined;
    }

    try {
      return url.split('/').reverse().find(seg => seg.includes('-'));
    } catch (error) {
      console.error('getSlugFromUrl', error);
      return undefined;
    }

  }

  async openPhotoViewer(src: string) {
    // Downloading the image first will hide placeholder image in the image viewer
    try {
      const modal = await this.modalController.create({
        component: ZoomableImageComponent,
        componentProps: { imageUrl: src },
        cssClass: 'zoomable-image-modal'
      });
      return await modal.present();
    } catch (e) {
      console.log('PhotoViewer Error', e);
    }
  }

  private async downloadImageToBase64(imageUrl: string) {
    try {
      const resp: Blob = await this.httpClient.get(imageUrl,
        {
          headers: {
            accept: 'image/png, image/webp, image/jpeg, image/gif'
          },
          responseType: 'blob'
        }).toPromise();
      return (await this.convertBlobToBase64(resp)) as string;
    } catch { }
  }

  private convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      return reader.readAsDataURL(blob);
    });
  }

  private isImage(url: string) {
    const supportedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];
    return supportedImageExtensions.some(ex => url.includes(ex));
  }

  private isRelativeUrl(url: string) {
    return url.startsWith('/');
  }

  private isLocalUrl(url: string) {
    return url.startsWith('/') || url.includes('localhost') || url.includes('0.0.0.0') || url.includes('127.0.0.1');
  }

  private getBasePath(url: string) {
    return new URL(url).pathname
      .split('/')
      .filter(el => el)
      .shift();
  }

  private stripTrailingSlash(path: string) {
    return path.replace(/\/+$/, '');
  }
}

export interface PostSection {
  // eslint-disable-next-line max-len
  type: 'text' | 'image' | 'podcast' | 'video' | 'tweet' | 'fade-out-readmore' | 'fade-out-free-download' | 'fade-out-misc' | 'btc-echo-plus' | 'paywall';
  html: SafeHtml;
  attributes?: {
    episodeId?: number;
    channelId?: string;
    videoLink?: string;
    videoId?: string;
    buttonLink?: string;
    fadeOutTitle?: string;
    fadeOutDescription?: string;
    fadeOutButtonLinkHref?: string;
    fadeOutButtonLinkText?: string;
  };
}

export interface LinkMeta {
  source?: string;
  title?: string;
}
