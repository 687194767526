import { Injectable, NgModule, LOCALE_ID, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { AppPipesModule } from './pipes/pipes.module';
import { GraphQLModule } from './graphql.module';
import { SentryModule } from './sentry.module';
import { OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { CacheModule } from 'ionic-cache';
import { environment } from 'src/environments/environment';
import { Preferences } from '@capacitor/preferences';

// Date Localization
import { DateFnsModule, DateFnsConfigurationService } from 'ngx-date-fns';
import { de } from 'date-fns/locale';
const germanConfig = new DateFnsConfigurationService();
germanConfig.setLocale(de);

// Angular Localization
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// Swiper
import { register } from 'swiper/element/bundle';
register();

// Global Components
import { UpdateNotifierModule } from './shared/components/update-notifier/update-notifier.module';
import { TokenExchangeService } from './services/utils/token-exchange.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Custom OAuth Token Storage Class to persist Tokens between App Sessions
 *
 * @see https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/configure-custom-oauthstorage.html
 * @see https://github.com/manfredsteyer/angular-oauth2-oidc/issues/943
 */
export class NativeOAuthStorage extends OAuthStorage {

  isCapacitor = inject(Platform).is('capacitor');
  tokenExchange = inject(TokenExchangeService);

  getItem(key: string) {
    // We cant get the values from capacitor preferences here,
    // because the angular oidc library does not support async functions.
    // The token exchange service holds the tokens due runtime.
    return this.tokenExchange.get(key);
  }

  removeItem(key: string) {
    this.tokenExchange.remove(key);
    Preferences.remove({
      key
    }).then(() => { });
  }

  setItem(key: string, value: string) {
    this.tokenExchange.set(key, value);
    // Store values persistently, so they are available on next app launch
    Preferences.set({
      key,
      value: value ? String(value) : ''
    }).then(() => { });
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot({
    mode: 'ios',
    backButtonText: 'Zurück',
    spinner: 'crescent'
  }),
    AppRoutingModule,
    HttpClientModule,
    AppPipesModule,
    ReactiveFormsModule,
    FormsModule,
    DateFnsModule.forRoot(),
    IonicStorageModule.forRoot({
      // eslint-disable-next-line no-underscore-dangle
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    }),
    CacheModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.unidy.issuer + '/api/', environment.unidy.issuer + '/oauth/'],
        sendAccessToken: true
      },
    }),
    GraphQLModule,
    SentryModule,
    UpdateNotifierModule
  ],
  providers: [
    TokenExchangeService,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: OAuthStorage, useClass: NativeOAuthStorage },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: DateFnsConfigurationService, useValue: germanConfig }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
